export default class ConsentimentoLgpdService {
  constructor(http) {
    this._http = http
  }

  async quantidadeConsentimentoEmUso(servidorId) {
    const data = this._http.get(`/api/consentimento-lgpd/buscar/${servidorId}`)

    return data
  }

  async create(consentimentoLgpd) {
    const { data } = this._http.post(
      '/api/consentimento-lgpd/consentimento',
      consentimentoLgpd,
    )

    return data
  }
}
