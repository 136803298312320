<template>
  <div class="text-justify" style="background-color: ghostwhite">
    <h3 class="text-center">Termo de Consentimento</h3>
    <p>{{ termo_consentimento }}</p>
  </div>
</template>
<script>
import Config from '@/config'
export default {
  data: () => ({
    termo_consentimento: Config.TERMO_CONSENTIMENTO_LGPD,
  }),
}
</script>
<style lang=""></style>
